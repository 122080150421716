<template>
  <div class="labs-page">
    <div>
      <button @click="actions.goToSideModal()">Side Modal</button>
    </div>
    <div>
      <button @click="actions.goToSticky()">Sticky</button>
    </div>
    <div>
      <button @click="actions.goToCalendar()">Calendar</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "Labs",
  setup() {
    const router = useRouter();

    const actions = {
      goToSideModal: () => {
        router.push({ name: "console.labs.sideModal" });
      },
      goToSticky: () => {
        router.push({ name: "console.labs.sticky" });
      },
      goToCalendar: () => {
        router.push({ name: "console.labs.calendar" });
      },
    };

    return { actions };
  },
};
</script>

<style scoped>
.labs-page {
  display: flex;
  gap: 30px;
}
</style>
